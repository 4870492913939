import * as yup from 'yup';
import { MessageConstant } from '../MessageConstant';

const message = MessageConstant.common;

export const oneOffRateValidation: { [x: string]: any; } = {
  rate: yup.number().required(message.MISSING),
  reasonCode: yup.string().required(message.MISSING),
};

export const accountTerminalValidation: { [x: string]: any; } = {
  reasonCode: yup.string().required(message.MISSING),
}

export const adjustmentValidation: { [x: string]: any; } = {
  adjustmentType: yup.string().required(message.MISSING),
  reasonCode: yup.string().required(message.MISSING),
};

export const bundleMasterValidation: { [x: string]: any; } = {
  bundleMasterNumber: yup.string().nullable(),
  bundleMasterVisit: yup.string()
  .when('bundleMasterNumber', (bundleMasterNumber, schema) => {
    if (bundleMasterNumber) {  
      return schema.test('is-bundle-visit-missing', 
        message.MISSING, 
        function(value) {  
          const masterNumber = this.parent.bundleMasterNumber;  
          const masterVisit = value;  
            
          if (masterNumber && !!!masterVisit) {  
            return false;  
          }
          return true;  
        });  
    }  
    return schema;
  })
};

export const updateOneOffRateValidation: { [x: string]: any; } = {
  ...oneOffRateValidation,
};

export const updateAccountTerminalValidation: { [x: string]: any; } = {
  ...accountTerminalValidation,
};

export const updateAdjustmentValidation: { [x: string]: any; } = {
  ...adjustmentValidation,
};

export const updateBundleMasterValidation: { [x: string]: any; } = {
  ...bundleMasterValidation,
};

export const updateOneOffRateValidationSchema = yup.object().shape(updateOneOffRateValidation);
export const updateAccountTerminalValidationSchema = yup.object().shape(updateAccountTerminalValidation);
export const updateAdjustmentValidationSchema = yup.object().shape(updateAdjustmentValidation);
export const updateBundleMasterValidationSchema = yup.object().shape(updateBundleMasterValidation);