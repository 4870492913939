import { isManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { uomDropdownOption, vatPaymentReferenceDateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { NonOpsConstant, nonOpsDtlRequiredFieldList } from "presentation/constant/NonOps/NonOpsConstant";
import { useNonOpsDetailVM } from "presentation/hook/NonOps/useNonOpsDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const NonOpsDetailFormPanel = () => {
    const [ nonOpsDetailState ] = useNonOpsDetailTracked();
    const nonOpsDetailVM = useNonOpsDetailVM();
    const messageBarVM = useMessageBarVM();    
    const [isLoading, setIsLoading] = useState(false);
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState, allowAutoRefresh, currentSelectedData, isAdjustmentRead} = nonOpsDetailState.viewState;
    const currentManChgDtl = currentSelectedData;
    const currentManChgHdr = nonOpsDetailState.viewState.editingHeader;
    const [ isAmtReadOnly, setIsAmtReadOnly ] = useState<boolean>(false);
    const [ isIndiviReadOnly, setIsIndiviReadOnly ] = useState<boolean>(false);

    const dynamicOptions = nonOpsDetailState.dynamicOptions;

    const HDR_CONST = NonOpsConstant.Header;
    const DTL_CONST = NonOpsConstant.Detail;

    useEffect(() => {
        setIsAmtReadOnly(isRead || (currentManChgDtl.individualChargeAmount!== null && currentManChgDtl.individualChargeAmount.toString() !== '')
                             || (currentManChgDtl.chargeQty!== null && currentManChgDtl.chargeQty.toString() !== ''));
        setIsIndiviReadOnly(isRead || 
            (currentManChgDtl.chargeAmount !== null && currentManChgDtl.chargeAmount.toString() !== ''/*&& !!!currentManChgDtl.individualChargeAmount && !!!currentManChgDtl.chargeQty*/));
    }, [currentManChgDtl.chargeAmount, currentManChgDtl.chargeQty, currentManChgDtl.individualChargeAmount, isRead]);
    
    const handleCancelClick = () => {
        nonOpsDetailVM.onCancelDetail();
    }
    
    const handleSaveClick = useCallback(()  => {
        setIsLoading(true);
        nonOpsDetailVM.onSaveClicked();
        nonOpsDetailVM.onSaveDetail(currentManChgDtl,isAdd).then((res) => {
            if(res && isManualChargeDetailEntity(res)){
                setIsLoading(false);
            }else{
                setIsLoading(false);
                if(res && res["mandatoryCheckFail"]){
                    messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                }               
            }
        }).catch((error) =>{
            setIsLoading(false);
            messageBarVM.showError(error.message);
        })

    },[currentManChgDtl, isAdd, nonOpsDetailVM, messageBarVM])

    const memoTariffCodeOptions = useMemo(() => {  
        return currentManChgDtl.tariffType  
            ? nonOpsDetailState.dynamicOptions.tariffCodeDropdownOptions[currentManChgDtl.tariffType]  
            : [];  
    }, [currentManChgDtl.tariffType, nonOpsDetailState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoTaxTariffCodeOptions = useMemo(() => {  
        return currentManChgDtl.vatTarType  
            ? nonOpsDetailState.dynamicOptions.taxTariffCodeDropdownOptions[currentManChgDtl.vatTarType]  
            : [];  
    }, [currentManChgDtl.vatTarType, nonOpsDetailState.dynamicOptions.taxTariffCodeDropdownOptions]);

    useEffect(() => {
        const refereshTaxInfo = async()=>{
            nonOpsDetailVM.onRefreshDefaultTarCode(currentManChgDtl.vatOneOffTaxCode,dynamicOptions.taxTariffTypeDropdownOptions,
                dynamicOptions.allTariffCodeeDropdownOptions,dynamicOptions.articleStatementCodeDropdownOptions
            );
        }        
        if(allowAutoRefresh){
            refereshTaxInfo();
        }
        
    },[allowAutoRefresh, currentManChgDtl.vatOneOffTaxCode, dynamicOptions.allTariffCodeeDropdownOptions, dynamicOptions.articleStatementCodeDropdownOptions, dynamicOptions.taxTariffCodeDropdownOptions, dynamicOptions.taxTariffTypeDropdownOptions, nonOpsDetailState.dynamicOptions.articleStatementCodeDropdownOptions, nonOpsDetailState.dynamicOptions.taxTariffCodeDropdownOptions, nonOpsDetailState.dynamicOptions.taxTariffTypeDropdownOptions, nonOpsDetailVM])    

    const handleUptCoa = (isTax: boolean) => {
        const tarType = isTax? currentManChgDtl.vatTarType : currentManChgDtl.tariffType;
        const tarCode = isTax? currentManChgDtl.vatTarCode : currentManChgDtl.tariffCode;
        if(!tarType || !tarCode){
            messageBarVM.showError((isTax? 'Tax ' : '') + 'Tariff Code is mandatory.');  
            return;
        }

        if(!isTax && !currentManChgDtl.individualChargeQtyUom ){
            messageBarVM.showError('Charge UOM is mandatory.');  
            return;
        }

        nonOpsDetailVM.onUpdateCoa(tarType,tarCode,isTax).catch((error)=>{
            messageBarVM.showError(error.message); 
        })
    }

    const handleGetUom = () => {  
        if (!currentManChgHdr.stdBillServiceDate) {  
            messageBarVM.showError('Please input the Service Date.');  
            return;
        }  
      
        if (!currentManChgDtl.tariffType || !currentManChgDtl.tariffCode) {  
            messageBarVM.showError('Please input the Tariff Code.'); 
            return; 
        }  
      
        nonOpsDetailVM.onGetUom(currentManChgDtl.chargeOnCompany, 
            currentManChgHdr.stdBillServiceDate,
            currentManChgDtl.tariffType,
            currentManChgDtl.tariffCode,
            currentManChgHdr.stdBillServiceDate,
            currentManChgHdr.stdBillServiceDate,
            currentManChgHdr.operatingTml
        ).then((data)=>{
            if (data && data["getUOMFail"]) {
                const err:string = data["getUOMFail"]?.toString()??"";
                messageBarVM.showError(err.replace("Error:", ""));
            }
        })
    }
    
    const onNumberFieldChange = useCallback((event: any,fieldName:string, fieldValue:any) => {
        nonOpsDetailVM.onFieldChange(fieldName, fieldValue);
        if (fieldName === "chargeAmount") {
            nonOpsDetailVM.onFieldChange("chargeQty", "");
        }
    }, [nonOpsDetailVM]);

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.dtlState || ''}
                fieldValue={currentManChgDtl?.dtlState}
                fieldLabel={HDR_CONST.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'dtlState'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.STATE, allFormState, currentManChgDtl?.dtlState, isSaveClicked])

    
    const memoAmt = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px",marginBottom:"24px"}} >
            <NumberInputComponent
                label={DTL_CONST.AMT}
                maxLength={200}
                fieldName={"chargeAmount"}
                value={currentManChgDtl?.chargeAmount??''}
                errorMessage={allFormState?.chargeAmount}
                disabled={isAmtReadOnly}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/* <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isAmtReadOnly}
                readOnlyValue={currentManChgDtl?.chargeAmount?.toString() || ''}
                fieldValue={currentManChgDtl?.chargeAmount?.toString()}
                fieldLabel={DTL_CONST.AMT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeAmount'}
                maxLength={200}
                isShowMissingError={false}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    nonOpsDetailVM.onFieldChange("chargeQty", "", fFullValue);
                }}  /> */}
        </div>
    , [DTL_CONST.AMT, allFormState?.chargeAmount, currentManChgDtl?.chargeAmount, isAmtReadOnly, onNumberFieldChange])

    
    // const memoTxtAmt = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.AMT}
    //             type="text"
    //             value={currentManChgDtl?.chargeAmount || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.AMT, currentManChgDtl?.chargeAmount])


    const memoIndividualChargeAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px",marginBottom:"24px"}} >
            <NumberInputComponent
                label={DTL_CONST.UNIT_PRICE}
                maxLength={200}
                fieldName={"individualChargeAmount"}
                value={currentManChgDtl?.individualChargeAmount??''}
                errorMessage={allFormState?.individualChargeAmount}
                disabled={isIndiviReadOnly}
                decimalPlaces={4}
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/* <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isIndiviReadOnly}
                readOnlyValue={currentManChgDtl?.individualChargeAmount?.toString() || ''}
                fieldValue={currentManChgDtl?.individualChargeAmount?.toString()}
                fieldLabel={DTL_CONST.UNIT_PRICE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'individualChargeAmount'}
                maxLength={200}
                isShowMissingError={false}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> */}
        </div>
    , [DTL_CONST.UNIT_PRICE, allFormState?.individualChargeAmount, currentManChgDtl?.individualChargeAmount, isIndiviReadOnly, onNumberFieldChange])

    // const memoTxtUnitPrice = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.UNIT_PRICE}
    //             type="text"
    //             value={currentManChgDtl?.individualChargeAmount || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.UNIT_PRICE, currentManChgDtl?.individualChargeAmount])

    const memoChargingQty = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={DTL_CONST.CHARGE_QTY}
                maxLength={200}
                fieldName={"chargeQty"}
                value={currentManChgDtl?.chargeQty??''}
                errorMessage={allFormState?.chargeQty}
                disabled={isIndiviReadOnly}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/* <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isIndiviReadOnly}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.chargeQty?.toString() || ''}
                fieldValue={currentManChgDtl?.chargeQty?.toString()}
                fieldLabel={DTL_CONST.CHARGE_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeQty'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> */}
        </div>
    , [DTL_CONST.CHARGE_QTY, allFormState?.chargeQty, currentManChgDtl?.chargeQty, isIndiviReadOnly, onNumberFieldChange])

    // const memoTxtQty = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.CHARGE_QTY}
    //             type="text"
    //             value={currentManChgDtl?.chargeQty || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.CHARGE_QTY, currentManChgDtl?.chargeQty])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"140px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.individualChargeQtyUom??''}
                fieldValue={currentManChgDtl?.individualChargeQtyUom??''}
                fieldLabel={DTL_CONST.UOM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'individualChargeQtyUom'}
                options={uomDropdownOption}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.UOM, allFormState, currentManChgDtl?.individualChargeQtyUom, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])


    const memoDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"530px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.dtlDesc1 || ''}
                fieldValue={currentManChgDtl?.dtlDesc1}
                fieldLabel={DTL_CONST.DTL_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'dtlDesc1'}
                isShowMissingError={false}
                maxLength={200}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}  />
        </div>
    , [DTL_CONST.DTL_DESC1, allFormState, currentManChgDtl?.dtlDesc1, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"530px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.dtlDesc2 || ''}
                fieldValue={currentManChgDtl?.dtlDesc2}
                fieldLabel={DTL_CONST.DTL_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'dtlDesc2'}
                maxLength={200}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.DTL_DESC2, allFormState, currentManChgDtl?.dtlDesc2, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.tariffType??''}
                fieldValue={currentManChgDtl?.tariffType??''}
                fieldLabel={DTL_CONST.TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={nonOpsDetailState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAR_TYPE, allFormState, currentManChgDtl?.tariffType, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.tariffTypeDropdownOptions, nonOpsDetailVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.tariffCode??''}
                fieldValue={currentManChgDtl?.tariffCode??''}
                fieldLabel={DTL_CONST.TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgDtl?.tariffCode, DTL_CONST.TAR_CODE, isSaveClicked, memoTariffCodeOptions, nonOpsDetailVM])

    const memoRevAcCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.revenueAccountCode || ''}
                fieldValue={currentManChgDtl?.revenueAccountCode}
                fieldLabel={DTL_CONST.REV_AC_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueAccountCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.REV_AC_CODE, allFormState, currentManChgDtl?.revenueAccountCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.costCenterCode || ''}
                fieldValue={currentManChgDtl?.costCenterCode}
                fieldLabel={DTL_CONST.COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'costCenterCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.COST_CENTER_CODE, allFormState, currentManChgDtl?.costCenterCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoProdServCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.productServiceCode || ''}
                fieldValue={currentManChgDtl?.productServiceCode}
                fieldLabel={DTL_CONST.PROD_SER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'productServiceCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.PROD_SER_CODE, allFormState, currentManChgDtl?.productServiceCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.salesChannelCode || ''}
                fieldValue={currentManChgDtl?.salesChannelCode}
                fieldLabel={DTL_CONST.SALES_CHANNEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'salesChannelCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.SALES_CHANNEL_CODE, allFormState, currentManChgDtl?.salesChannelCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    // const memoCountryCode = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={10}
    //             label={DTL_CONST.COUNTRY_CODE}
    //             type="text"
    //             value={currentManChgDtl?.countryCode || ''} 
    //             disabled={true}
    //             />            
    //     </div>
    // , [DTL_CONST.COUNTRY_CODE, currentManChgDtl?.countryCode])   

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.countryCode || ''}
                fieldValue={currentManChgDtl?.countryCode}
                fieldLabel={DTL_CONST.COUNTRY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'countryCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.COUNTRY_CODE, allFormState, currentManChgDtl?.countryCode, isSaveClicked])
    
    const memoProjCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.projectCode || ''}
                fieldValue={currentManChgDtl?.projectCode}
                fieldLabel={DTL_CONST.PROJECT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'projectCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.PROJECT_CODE, allFormState, currentManChgDtl?.projectCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.spareCode || ''}
                fieldValue={currentManChgDtl?.spareCode}
                fieldLabel={DTL_CONST.SPARE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'spareCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.SPARE_CODE, allFormState, currentManChgDtl?.spareCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    // const memoRevCmpCode = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={10}
    //             label={HDR_CONST.COMPANY_CODE}
    //             type="text"
    //             value={currentManChgDtl?.revenueCompanyCode || ''} 
    //             disabled={true}
    //             />            
    //     </div>
    // , [HDR_CONST.COMPANY_CODE, currentManChgDtl?.revenueCompanyCode])   

    const memoRevCmpCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.revenueCompanyCode || ''}
                fieldValue={currentManChgDtl?.revenueCompanyCode}
                fieldLabel={HDR_CONST.COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueCompanyCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.COMPANY_CODE, allFormState, currentManChgDtl?.revenueCompanyCode, isSaveClicked])
    
    const memoInterCmpCode= useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.intercompanyCode??''}
                fieldValue={currentManChgDtl?.intercompanyCode??''}
                fieldLabel={DTL_CONST.INTER_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'intercompanyCode'}
                options={nonOpsDetailState.dynamicOptions.interCmpCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.INTER_COMPANY_CODE, allFormState, currentManChgDtl?.intercompanyCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.interCmpCodeDropdownOptions, nonOpsDetailVM])



    const memoTaxCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatOneOffTaxCode??''}
                fieldValue={currentManChgDtl?.vatOneOffTaxCode??'' }
                fieldLabel={DTL_CONST.TAX_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatOneOffTaxCode'}
                options={nonOpsDetailState.dynamicOptions.taxCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_CODE, allFormState, currentManChgDtl?.vatOneOffTaxCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.taxCodeDropdownOptions, nonOpsDetailVM])

    // const memoAdjType = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.ADJ_TYPE}
    //             type="text"
    //             value={currentManChgDtl?.adjType || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.ADJ_TYPE, currentManChgDtl?.adjType])

    const memoAdjType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.adjType || ''}
                fieldValue={currentManChgDtl?.adjType}
                fieldLabel={DTL_CONST.ADJ_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'adjType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.ADJ_TYPE, allFormState, currentManChgDtl?.adjType, isSaveClicked])
    

    // const memoRefNoAdjFrom = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.REF_NO_ADJ_FROM}
    //             type="text"
    //             value={currentManChgDtl?.adjRefNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.REF_NO_ADJ_FROM, currentManChgDtl?.adjRefNo])

    const memoRefNoAdjFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.adjRefNo || ''}
                fieldValue={currentManChgDtl?.adjRefNo}
                fieldLabel={DTL_CONST.REF_NO_ADJ_FROM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'adjRefNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.REF_NO_ADJ_FROM, allFormState, currentManChgDtl?.adjRefNo, isSaveClicked])

        
    // Tax %
    const memoOneOffRatePercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NumberInputComponent
                label={DTL_CONST.TAX_PERCENTAGE}
                maxLength={60}
                fieldName={"vatPercentage"}
                value={currentManChgDtl?.vatPercentage??''}
                errorMessage={allFormState?.vatPercentage??''}
                disabled={isRead || isAdjustmentRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatPercentage?.toString() || ''}
                fieldValue={currentManChgDtl?.vatPercentage?.toString()}
                fieldLabel={DTL_CONST.TAX_PERCENTAGE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatPercentage'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
    , [DTL_CONST.TAX_PERCENTAGE, allFormState, currentManChgDtl?.vatPercentage, isAdjustmentRead, isRead, onNumberFieldChange])

    const memoTaxTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl.vatTarType??''}
                fieldValue={currentManChgDtl.vatTarType??''}
                fieldLabel={DTL_CONST.TAX_TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTarType'}
                options={nonOpsDetailState.dynamicOptions.taxTariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_TAR_TYPE, allFormState, currentManChgDtl.vatTarType, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.taxTariffTypeDropdownOptions, nonOpsDetailVM])


    const memoTaxTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatTarCode??''}
                fieldValue={currentManChgDtl?.vatTarCode??''}
                fieldLabel={DTL_CONST.TAX_TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTarCode'}
                options={memoTaxTariffCodeOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgDtl?.vatTarCode, DTL_CONST.TAX_TAR_CODE, isSaveClicked, memoTaxTariffCodeOptions, nonOpsDetailVM])


    const memoTaxRevAcCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxRevenueAccountCode || ''}
                fieldValue={currentManChgDtl?.taxRevenueAccountCode}
                fieldLabel={DTL_CONST.TAX_REV_AC_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxRevenueAccountCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_REV_AC_CODE, allFormState, currentManChgDtl?.taxRevenueAccountCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTaxCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxCostCenterCode || ''}
                fieldValue={currentManChgDtl?.taxCostCenterCode}
                fieldLabel={DTL_CONST.TAX_COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxCostCenterCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_COST_CENTER_CODE, allFormState, currentManChgDtl?.taxCostCenterCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTaxProdServCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxProductServiceCode || ''}
                fieldValue={currentManChgDtl?.taxProductServiceCode}
                fieldLabel={DTL_CONST.TAX_PROD_SER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxProductServiceCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_PROD_SER_CODE, allFormState, currentManChgDtl?.taxProductServiceCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTaxSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxSalesChannelCode || ''}
                fieldValue={currentManChgDtl?.taxSalesChannelCode}
                fieldLabel={DTL_CONST.TAX_SALES_CHANNEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxSalesChannelCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_SALES_CHANNEL_CODE, allFormState, currentManChgDtl?.taxSalesChannelCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    // const memoTaxCountryCode = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={10}
    //             label={DTL_CONST.TAX_COUNTRY_CODE}
    //             type="text"
    //             value={currentManChgDtl?.taxCountryCode || ''} 
    //             disabled={true}
    //             />            
    //     </div>
    // , [DTL_CONST.TAX_COUNTRY_CODE, currentManChgDtl?.taxCountryCode])   

    const memoTaxCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxCountryCode || ''}
                fieldValue={currentManChgDtl?.taxCountryCode}
                fieldLabel={DTL_CONST.TAX_COUNTRY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxCountryCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.TAX_COUNTRY_CODE, allFormState, currentManChgDtl?.taxCountryCode, isSaveClicked])
    
    const memoTaxProjCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxProjectCode || ''}
                fieldValue={currentManChgDtl?.taxProjectCode}
                fieldLabel={DTL_CONST.TAX_PROJECT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxProjectCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_PROJECT_CODE, allFormState, currentManChgDtl?.taxProjectCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTaxSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxSpareCode || ''}
                fieldValue={currentManChgDtl?.taxSpareCode}
                fieldLabel={DTL_CONST.TAX_SPARE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxSpareCode'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_SPARE_CODE, allFormState, currentManChgDtl?.taxSpareCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    // const memoTaxRevCmpCode = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={10}
    //             label={DTL_CONST.TAX_COMPANY_CODE}
    //             type="text"
    //             value={currentManChgDtl?.taxRevenueCompanyCode || ''} 
    //             disabled={true}
    //             />            
    //     </div>
    // , [DTL_CONST.TAX_COMPANY_CODE, currentManChgDtl?.taxRevenueCompanyCode])   

    const memoTaxRevCmpCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxRevenueCompanyCode || ''}
                fieldValue={currentManChgDtl?.taxRevenueCompanyCode}
                fieldLabel={DTL_CONST.TAX_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'taxRevenueCompanyCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.TAX_COMPANY_CODE, allFormState, currentManChgDtl?.taxRevenueCompanyCode, isSaveClicked])
    
    const memoTaxInterCmpCode= useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.taxIntercompanyCode??''}
                fieldValue={currentManChgDtl?.taxIntercompanyCode??''}
                fieldLabel={DTL_CONST.TAX_INTER_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'taxIntercompanyCode'}
                options={nonOpsDetailState.dynamicOptions.interCmpCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_INTER_COMPANY_CODE, allFormState, currentManChgDtl?.taxIntercompanyCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.interCmpCodeDropdownOptions, nonOpsDetailVM])

    // const memoTxType = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.TX_TYPE}
    //             type="text"
    //             value={currentManChgDtl?.vatTransactionType || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.TX_TYPE, currentManChgDtl?.vatTransactionType])

    const memoTxType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatTransactionType || ''}
                fieldValue={currentManChgDtl?.vatTransactionType}
                fieldLabel={DTL_CONST.TX_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTransactionType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.TX_TYPE, allFormState, currentManChgDtl?.vatTransactionType, isSaveClicked])


    // const memoInvNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={HDR_CONST.INV_NO}
    //             type="text"
    //             value={currentManChgDtl?.invoiceNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [HDR_CONST.INV_NO, currentManChgDtl?.invoiceNo])

    
    const memoInvNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.invoiceNo || ''}
                fieldValue={currentManChgDtl?.invoiceNo}
                fieldLabel={HDR_CONST.INV_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'invoiceNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.INV_NO, allFormState, currentManChgDtl?.invoiceNo, isSaveClicked])
    
    // const memoCreditNoteNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={HDR_CONST.CREDIT_NOTE_NO}
    //             type="text"
    //             value={currentManChgDtl?.creditNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [HDR_CONST.CREDIT_NOTE_NO, currentManChgDtl?.creditNo])

    const memoCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.creditNo || ''}
                fieldValue={currentManChgDtl?.creditNo}
                fieldLabel={HDR_CONST.CREDIT_NOTE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'creditNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.CREDIT_NOTE_NO, allFormState, currentManChgDtl?.creditNo, isSaveClicked])

    // const memoDraftInvoiceNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={HDR_CONST.DRAFT_INV_NO}
    //             type="text"
    //             value={currentManChgDtl?.draftInvoiceNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [HDR_CONST.DRAFT_INV_NO, currentManChgDtl?.draftInvoiceNo])

    
    const memoDraftInvoiceNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.draftInvoiceNo || ''}
                fieldValue={currentManChgDtl?.draftInvoiceNo}
                fieldLabel={HDR_CONST.DRAFT_INV_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'draftInvoiceNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.DRAFT_INV_NO, allFormState, currentManChgDtl?.draftInvoiceNo, isSaveClicked])

    // const memoDraftCreditNoteNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={HDR_CONST.DRAFT_CREDIT_NOTE_NO}
    //             type="text"
    //             value={currentManChgDtl?.draftCreditNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [HDR_CONST.DRAFT_CREDIT_NOTE_NO, currentManChgDtl?.draftCreditNo])

    const memoDraftCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.draftCreditNo || ''}
                fieldValue={currentManChgDtl?.draftCreditNo}
                fieldLabel={HDR_CONST.DRAFT_CREDIT_NOTE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'draftCreditNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [HDR_CONST.DRAFT_CREDIT_NOTE_NO, allFormState, currentManChgDtl?.draftCreditNo, isSaveClicked])

 
    // const memoExMsg = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.EX_MSG}
    //             type="text"
    //             value={currentManChgDtl?.exceptionMsg || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.EX_MSG, currentManChgDtl?.exceptionMsg])

    const memoExMsg = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.exceptionMsg || ''}
                fieldValue={currentManChgDtl?.exceptionMsg}
                fieldLabel={DTL_CONST.EX_MSG}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'exceptionMsg'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.EX_MSG, allFormState, currentManChgDtl?.exceptionMsg, isSaveClicked])


    
    const memoArticleStatementCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatArticleStatementCode??''}
                fieldValue={currentManChgDtl?.vatArticleStatementCode??''}
                fieldLabel={DTL_CONST.ARTICLE_STATEMENT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatArticleStatementCode'}
                options={nonOpsDetailState.dynamicOptions.articleStatementCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.ARTICLE_STATEMENT_CODE, allFormState, currentManChgDtl?.vatArticleStatementCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.articleStatementCodeDropdownOptions, nonOpsDetailVM])

    
    // const memoArticleStatement = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.ARTICLE_STATEMENT}
    //             type="text"
    //             value={currentManChgDtl?.vatArticleStatement || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.ARTICLE_STATEMENT, currentManChgDtl?.vatArticleStatement])

    const memoArticleStatement = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatArticleStatement || ''}
                fieldValue={currentManChgDtl?.vatArticleStatement}
                fieldLabel={DTL_CONST.ARTICLE_STATEMENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatArticleStatement'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.ARTICLE_STATEMENT, allFormState, currentManChgDtl?.vatArticleStatement, isSaveClicked])


    const memoVatDays = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={false}
                readOnlyValue={String(currentManChgDtl?.vatDays || '')}
                fieldValue={String(currentManChgDtl?.vatDays || '')}
                fieldLabel={DTL_CONST.TAX_PAYMENT_TERM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDays'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_PAYMENT_TERM, allFormState, currentManChgDtl?.vatDays, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoVatRefDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.paymentReferenceDate??''}
                fieldValue={currentManChgDtl?.paymentReferenceDate??''}
                fieldLabel={DTL_CONST.TAX_PAYMENT_REF_TERM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDate'}
                isShowMissingError={false}
                options={vatPaymentReferenceDateDropdownOption}
                maxLength={60}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [DTL_CONST.TAX_PAYMENT_REF_TERM, allFormState, currentManChgDtl?.paymentReferenceDate, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])


    // const memoReasonCode = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='250px'
    //             maxLength={100}
    //             label={DTL_CONST.REASON_CODE}
    //             type="text"
    //             value={currentManChgDtl?.reasonCode || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [DTL_CONST.REASON_CODE, currentManChgDtl?.reasonCode])

    const memoReasonCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.reasonCode || ''}
                fieldValue={currentManChgDtl?.reasonCode}
                fieldLabel={DTL_CONST.REASON_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reasonCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
    , [DTL_CONST.REASON_CODE, allFormState, currentManChgDtl?.reasonCode, isSaveClicked])


    const memoTaxDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatDesc1 || ''}
                fieldValue={currentManChgDtl?.vatDesc1}
                fieldLabel={DTL_CONST.TAX_DTL_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDesc1'}
                maxLength={200}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}  />
        </div>
    , [DTL_CONST.TAX_DTL_DESC1, allFormState, currentManChgDtl?.vatDesc1, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    const memoTaxDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgDtl?.vatDesc2 || ''}
                fieldValue={currentManChgDtl?.vatDesc2}
                fieldLabel={DTL_CONST.TAX_DTL_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDesc2'}
                maxLength={200}
                requiredFieldList={nonOpsDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}  />
        </div>
    , [DTL_CONST.TAX_DTL_DESC2, allFormState, currentManChgDtl?.vatDesc2, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Detail'}</SidebarTitle>                        
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoState}
                            {memoAmt}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoDesc1}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoDesc2}
                        </CriteriaItemContainer>                       
                        
                        <CriteriaItemContainer>
                            {memoTarType}
                            {memoTarCode}
                            <div className='im-flex-row-item'>
                                <HPHButton label={DTL_CONST.BTN_UPT_COA} size={'Small'} theme={'Secondary'} disabled={isRead || isAdjustmentRead}  onClick={e => handleUptCoa(false)} />          
                            </div>
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>                            
                            {memoIndividualChargeAmount}
                            { memoChargingQty}
                            {memoUom}
                            <div className='im-flex-row-item'>
                                <HPHButton label={DTL_CONST.BTN_GET_UOM} size={'Small'} theme={'Secondary'} disabled={isRead || isAdjustmentRead}  onClick={handleGetUom} />
                            </div>          
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRevAcCode}
                            {memoCostCenterCode}
                            {memoProdServCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSalesChannelCode}
                            {memoCountryCode}
                            {memoProjCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSpareCode}
                            {memoRevCmpCode}
                            {memoInterCmpCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxCode}
                            {memoAdjType}
                            {memoOneOffRatePercentage}
                        </CriteriaItemContainer>   

                        <CriteriaItemContainer>
                            {memoTaxTarType}
                            {memoTaxTarCode}                            
                            <div className='im-flex-row-item'>
                                <HPHButton label={DTL_CONST.BTN_UPT_TAX_COA} size={'Small'} theme={'Secondary'} disabled={isRead || isAdjustmentRead} onClick={e => handleUptCoa(true)} />          
                            </div>
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxRevAcCode}
                            {memoTaxCostCenterCode}
                            {memoTaxProdServCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxSalesChannelCode}
                            {memoTaxCountryCode}
                            {memoTaxProjCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTaxSpareCode}
                            {memoTaxRevCmpCode}
                            {memoTaxInterCmpCode}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoTxType}
                            {memoVatDays}
                            {memoVatRefDate}
                        </CriteriaItemContainer>
    

                        <CriteriaItemContainer>                                         
                            {memoArticleStatementCode}
                            {memoArticleStatement}
                            {memoTaxDesc1}
                        </CriteriaItemContainer>                      
                        
                        <CriteriaItemContainer>                                            
                            {memoTaxDesc2}
                            {memoRefNoAdjFrom}
                            {memoInvNo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCreditNoteNo}
                            {memoDraftInvoiceNo}
                            {memoDraftCreditNoteNo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>                                                             
                            {memoReasonCode}                    
                            {memoExMsg}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={isRead?'Close':'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {!isRead&&<HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                    </>
                }
            </div>
            
        </div>
    )
}