import { ManualChargeDetailCntrEntity } from "./ManualChargeDetailCntrEntity";
import { ManualChargeHeaderEntity } from "./ManualChargeHeaderEntity";

export interface ManualChargeDetailEntity {
    id: number | null,
	manualChargeHdrId: number | null,
	chargeType: string | null,
	subChargeType: string | null,
	dtlState: string | null,
	tariffType: string | null,
	tariffCode: string | null,
	tariffCodeDesc: string | null,
	cntrList: string | null,
	chargeOnCompany: string | null,
	unitPerQty: number | null, 
    chargeQty: number | null,
	fromDate: Date | null,
	toDate: Date | null,
	storageFromDateCal: Date | null,
	storageToDateCal: Date | null,
	qty: number | null,
	individualChargeQty: number | null,
	individualChargeQtyUom: string | null, 
	logNo: string | null,
	additionalChargeQty: number | null, 
	additionalChargeQtyUom: string | null,
	rate: number | null, 
	rateType: string | null,
	chargeSharePercentage: number | null, 
	chargePercentage: number | null, 
	shiftCode: string | null,
	adjRefNo: string | null,
	adjType: string | null,
	chargeInd: string | null,
	forwarderCode: string | null,
	reasonCode: string | null,
	operationType: string | null,
	cntrTspStatus: string | null,
	partnerCode: string | null,
	emptyIndicator: string | null,
	exceptionMsg: string | null,

	// Adj
	vatTarType: string | null,
	vatTarCode: string | null,
	vatTariffCodeDesc: string | null,
	vatPercentage: number | null, 
	vatOneOffTaxCode: string | null, 
	vatTransactionType: string | null, 
	vatArticleStatementCode: string | null,
	vatArticleStatement: string | null,
	vatDays: number | null, 
	vatDaysCredit: number | null, 
	vatDaysDebit: number | null, 
	paymentReferenceDate: string | null, 
	paymentReferenceDateCredit: string | null, 
	paymentReferenceDateDebit: string | null, 


	invoiceNo: string | null,
	creditNo: string | null,
	draftInvoiceNo: string | null,
	draftCreditNo: string | null,
	
    manualChargeHdr: ManualChargeHeaderEntity | null,
    
    // non ops
    individualChargeAmount: number | null,
    chargeAmount: number | null,
    dtlDesc1: string | null,
    dtlDesc2: string | null,
    revenueCompanyCode: string | null,
    revenueAccountCode: string | null,
    costCenterCode: string | null,
    productServiceCode: string | null,
    salesChannelCode: string | null,
    countryCode: string | null,
    intercompanyCode: string | null,
    projectCode: string | null,
    spareCode: string | null,

    // non ops adj
    taxRevenueCompanyCode: string | null,
    taxRevenueAccountCode: string | null,
    taxCostCenterCode: string | null,
    taxProductServiceCode: string | null,
    taxSalesChannelCode: string | null,
    taxCountryCode: string | null,
    taxIntercompanyCode: string | null,
    taxProjectCode: string | null,
    taxSpareCode: string | null,
    
    vatDesc1: string | null,
    vatDesc2: string | null,

    dummyKey: string | null,
    manChgDtlCntrs: ManualChargeDetailCntrEntity[],
    initManChargeDtlCntrs: ManualChargeDetailCntrEntity[]

    [key: string]: string | boolean | null | Object | number | undefined
}

export const EMPTY_MANUAL_CHARGE_DETAIL_ENTITY : ManualChargeDetailEntity = {
    id: null,
    manualChargeHdrId: null,
    chargeType: null,
    subChargeType: null,
    dtlState: 'E',
    tariffType: null,
    tariffCode: null,
    tariffCodeDesc: null,
    cntrList: null,
    chargeOnCompany: null,
    unitPerQty: null,
    chargeQty: null,
    fromDate: null,
    toDate: null,
    storageFromDateCal: null,
    storageToDateCal: null,
    qty: null,
    individualChargeQty: null,
    individualChargeQtyUom: null,
    logNo: null,
    additionalChargeQty: null,
    additionalChargeQtyUom: null,
    rate: null,
    rateType: null,
    chargeSharePercentage: null,
    chargePercentage: null,
    shiftCode: null,
    adjRefNo: null,
    adjType: null,
    chargeInd: null,
    forwarderCode: null,
    reasonCode: null,
    operationType: null,
    cntrTspStatus: null,
    partnerCode: null,
    emptyIndicator: null,
    exceptionMsg: null,
    vatTarType: null,
    vatTarCode: null,
    vatTariffCodeDesc: null,
    vatPercentage: null,
    vatOneOffTaxCode: null,
    vatTransactionType: null,
    vatArticleStatementCode: null,
    vatArticleStatement: null,
    vatDays: null,
    vatDaysCredit: null,
    vatDaysDebit: null,
    paymentReferenceDate: null,
    paymentReferenceDateCredit: null,
    paymentReferenceDateDebit: null,
    invoiceNo: null,
    creditNo: null,
    draftInvoiceNo: null,
    draftCreditNo: null,
    manualChargeHdr: null,
    individualChargeAmount: null,
    chargeAmount: null,
    dtlDesc1: null,
    dtlDesc2: null,
    revenueCompanyCode: null,
    revenueAccountCode: null,
    costCenterCode: null,
    productServiceCode: null,
    salesChannelCode: null,
    countryCode: null,
    intercompanyCode: null,
    projectCode: null,
    spareCode: null,
    taxRevenueCompanyCode: null,
    taxRevenueAccountCode: null,
    taxCostCenterCode: null,
    taxProductServiceCode: null,
    taxSalesChannelCode: null,
    taxCountryCode: null,
    taxIntercompanyCode: null,
    taxProjectCode: null,
    taxSpareCode: null,
    vatDesc1: null,
    vatDesc2: null,
    dummyKey: null,
    manChgDtlCntrs: [],
    initManChargeDtlCntrs: []
}

export const isManualChargeDetailEntity = (obj: any) => {
    return obj && typeof obj.manualChargeHdrId === 'number';  
}