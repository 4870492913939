import { CustomCellRendererProps } from "ag-grid-react";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { NonOpsConstant } from "./NonOpsConstant";

const NON_OPS_HDR_CONSTANT = NonOpsConstant.Header;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_NON_OPS_HDR_COL_DEF: any[] = [
    {
        headerName: NON_OPS_HDR_CONSTANT.STATE,
        field: 'hdrState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.REF_NO,
        field: 'manChargeNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.ORDER_NO,
        field: 'orderNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.STD_BILL,
        field: 'stdBillNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.BILLING_CYCLE,
        field: 'stdBillBillingCycle',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.SERV_DATE,
        field: 'stdBillServiceDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.EFF_DATE,
        field: 'stdBillEffectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.EXP_DATE,
        field: 'stdBillExpiryDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CUST_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CUST_NAME,
        field: 'customerName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CHG_DESC,
        field: 'chargeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.YOUR_REF,
        field: 'yourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.OUR_REF,
        field: 'ourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.REMARK,
        field: 'remarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.SUBJECT,
        field: 'subject',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.HDR_DESC1,
        field: 'hdrDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.HDR_DESC2,
        field: 'hdrDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.INV_NO,
        field: 'invoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 170,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.DRAFT_INV_NO,
        field: 'draftInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.DRAFT_CREDIT_NOTE_NO,
        field: 'draftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['hdrState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['hdrState'] = calculateStateColor;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}