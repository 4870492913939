import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { useNonOpsDetailVM } from "presentation/hook/NonOps/useNonOpsDetailVM";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { NonOpsHeaderFieldFormPanel } from "./Form/NonOpsHeaderFieldFormPanel";
import NonOpsDetailContent from "./NonOpsDetailContent";
import NonOpsDetailTitleBar from "./NonOpsDetailTitleBar";


const NonOpsDetailMaintenance:React.FC = () => {

    const [ , setNonOpsDtlState ] = useNonOpsDetailTracked();
    const [ nonOpsHdrState ] = useNonOpsHeaderTracked();
    const nonOpsDtlVM = useNonOpsDetailVM();
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const [initial, setInitial] = useState<boolean>(false);
    const {currentManualChargeHeader} = nonOpsHdrState;

    const initialScreen = useCallback(async() => {
        if (!currentManualChargeHeader) return;
        nonOpsDtlVM.onShowLoading();
        try {
            const results = await Promise.allSettled([
                nonOpsDtlVM.loadDropdownOption(),
                nonOpsDtlVM.onPageInit(currentManualChargeHeader),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                  } else if (index === 1 && result.status === 'rejected') {

                  }
            });
            nonOpsDtlVM.onHideLoading();
            setInitial(false);
        } catch(error) {
        }
    }, [currentManualChargeHeader, nonOpsDtlVM]);

    const initialRedirectScreen = useCallback(async() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        if (!manSysCharge) return;
        nonOpsDtlVM.onShowLoading();

        await Promise.allSettled([
            nonOpsDtlVM.loadDropdownOption(),
            nonOpsHdrVM.onSearchById(manSysCharge.hdrId??0).then(data => {
                if (data) {
                    const manualChargeHeader = data;

                    nonOpsDtlVM.onPageInit(manualChargeHeader);
                    setNonOpsDtlState(prevState => ({
                        ...prevState,
                        currentManualChargeHeader: {
                            ...prevState.currentManualChargeHeader,
                            ...manualChargeHeader
                        },
                        isShowCloseIcon: false
                    }));
                    nonOpsDtlVM.onSearch(manualChargeHeader.id??0).then(data => {
                        nonOpsDtlVM.onHideLoading();
                        setInitial(false);
                    }).catch(error => {                        
                        nonOpsDtlVM.onHideLoading();
                        setInitial(false);
                    }).finally(() => {                        
                        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH);
                    })
                }
            }),
        ]).catch(error => {            
            nonOpsDtlVM.onHideLoading();
            setInitial(false);            
            localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH);
        });
        
        
    }, [nonOpsDtlVM, nonOpsHdrVM, setNonOpsDtlState])

    useEffect(() => {
        const onNonOpsDetailRedirect = async(e:any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH:                    
                    const manSysCharge = (e.detail.data.data as ManualSystemChargeEntity);
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH, JSON.stringify(manSysCharge));
                    setInitial(true);

                    break;
            }
        }

        customEventListener(E_Type_Of_Event.NON_OPS_DETAIL_REDIRECT_EVENT_INITIAL, onNonOpsDetailRedirect);
    }, [nonOpsDtlVM, nonOpsHdrVM, setNonOpsDtlState]);

    useEffect(() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        
        if (!manSysCharge) {
            initialScreen().then(()=>{
                currentManualChargeHeader?.id && nonOpsDtlVM.onSearch(currentManualChargeHeader.id)
            });

        } else {
            initialRedirectScreen();
        }    
    }, [nonOpsDtlVM, initial, initialRedirectScreen, initialScreen, currentManualChargeHeader?.id])

    return <>
        <div className="main-comp-wrapper">
            <NonOpsDetailTitleBar/>
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"38%"}
                rightSectionWidth={"62%"}
                leftChildren={<NonOpsHeaderFieldFormPanel/>}
                rightChildren={<NonOpsDetailContent/>}
            />
        </div>
    </>
}

export default memo(NonOpsDetailMaintenance)